<template>
  <div id="award-category-edit">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-title></v-card-title>
      <v-card-text>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container class="py-0">
            <v-row wrap>
              <v-col cols="12" md="12">
                <p>
                  <span class="text-danger">*</span> indicates required field
                </p>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Organization Type</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="OrganizationTypeOptions"
                  :rules="OrganizationTypeRules"
                  v-model="OrganizationType"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="6">
                <h6><span class="text-danger">*</span> Award Category Name</h6>
                <v-text-field
                  v-model="AwardCategoryName"
                  placeholder="Award Category Name"
                  :rules="AwardCategoryNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                :disabled="!valid1"
                @click.prevent="confirmAlert"
                :loading="SubmitFlag"
                elevation="30"
                rounded
                medium
                color="#1db954"
                class="font-size-h6 px-4 mr-3 white--text"
              >
                Submit
              </v-btn>
              <v-btn
                @click="closePrompt"
                elevation="30"
                rounded
                medium
                color="#a52a2a"
                class="font-size-h6 px-4 mr-3 white--text"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    editRecordPrompt: {
      required: true,
      type: Boolean,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      AwardCategoryId: "",

      OrganizationTypeRules: [(v) => !!v || "Organization Type is required"],
      OrganizationType: "",
      OrganizationTypeOptions: [],

      AwardCategoryNameRules: [(v) => !!v || "Category Name is required"],
      AwardCategoryName: "",
      AwardCategoryNameOptions: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getOrganizationTypeOptions();

        console.log("recordData = ", this.recordData);
        this.AwardCategoryId = this.recordData.AwardCategoryId;
        this.OrganizationType = this.recordData.OrganizationTypeId;
        this.AwardCategoryName = this.recordData.AwardCategoryName;
      }
    },
    OrganizationTypeOptions: function () {
      console.log("watch OrganizationTypeOptions");
      this.OrganizationTypeOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "award_category",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.LoadingFlag = false;
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    getOrganizationTypeOptions() {
      console.log("getOrganizationTypeOptions called");
      var selectbox1_source = "OrganizationType";
      var selectbox1_destination = "OrganizationTypeOptions";
      var selectbox1_url = "api/organization-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      this.SubmitFlag = true;
      this.ResultFlag = false;

      var upload = {
        UserInterface: 1,
        AwardCategory: this.AwardCategoryId,
        OrganizationType: this.OrganizationType,
        AwardCategoryName: this.AwardCategoryName,
      };
      console.log("upload=" + JSON.stringify(upload));

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/award-category/update";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SubmitFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.ResultFlag = true;
            thisIns.closePrompt();
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
</style>